var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualityInspectionPlanForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
              form: _vm.form,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "计划名称",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入计划名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      width: 425,
                      maxlength: 50,
                      placeholder: "请输入计划名称",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "计划层级",
                    prop: "scopeType",
                    rules: [
                      {
                        required: true,
                        message: "请选择计划层级",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm.isView
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.scopeTypeMap[_vm.form.scopeType])),
                      ])
                    : _c("v-select", {
                        attrs: { options: _vm.scopeTypeOps },
                        model: {
                          value: _vm.form.scopeType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "scopeType", $$v)
                          },
                          expression: "form.scopeType",
                        },
                      }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "计划类型",
                    prop: "planType",
                    rules: [
                      {
                        required: true,
                        message: "请选择计划类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm.isView
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.planTypeMap[_vm.form.planType])),
                      ])
                    : _c("v-select", {
                        attrs: { options: _vm.planTypeOps },
                        model: {
                          value: _vm.form.planType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "planType", $$v)
                          },
                          expression: "form.planType",
                        },
                      }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "计划责任部门",
                    prop: "responsibleDeptId",
                    rules: [
                      {
                        required: true,
                        message: "请选择计划责任部门",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm.isView
                    ? _c("span", [_vm._v(_vm._s(_vm.form.responsibleDeptName))])
                    : _vm._e(),
                  _c("select-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.treeLoading,
                        expression: "treeLoading",
                      },
                    ],
                    staticClass: "tree",
                    attrs: {
                      value: _vm.form.responsibleDeptId,
                      placeholder: "查询所属部门",
                      treeData: _vm.treeData,
                      treeProps: _vm.treeProps,
                      highlightCurrent: true,
                      filterNodeMethod: _vm.filterNodeMethod,
                      "expand-on-click-node": false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.form, "responsibleDeptId", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              !!_vm.form.responsibleDeptId
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "计划责任人",
                        prop: "responsibleUserId",
                        rules: [
                          {
                            required: true,
                            message: "请选择计划责任人",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _vm.isView
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.categoryLevelMap[_vm.form.categoryLevel]
                              )
                            ),
                          ])
                        : _c("v-select", {
                            attrs: { filterable: "", options: _vm.userOps },
                            model: {
                              value: _vm.form.responsibleUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "responsibleUserId", $$v)
                              },
                              expression: "form.responsibleUserId",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.form.responsibleDeptId && _vm.form.planType === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "计划执行人",
                        prop: "executorIds",
                        rules: [
                          {
                            required: true,
                            message: "请选择计划执行人",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          textAs: "name",
                          disabled: _vm.type === "view",
                          list: _vm.form.executorIds,
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "executorIds", $event)
                          },
                          select: _vm.toSelectRoleIds,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "检查表模板",
                    prop: "templateId",
                    rules: [
                      {
                        required: true,
                        message: "请选择检查表模板",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _vm.isView
                    ? _c("span", [_vm._v(_vm._s(_vm.form.templateName))])
                    : _c("v-select", {
                        attrs: { filterable: "", options: _vm.templateOps },
                        model: {
                          value: _vm.form.templateId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "templateId", $$v)
                          },
                          expression: "form.templateId",
                        },
                      }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "计划起止时间",
                    rules: _vm.timeRules,
                    prop: "planStartTime",
                  },
                },
                [
                  _vm.isView
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.form.planStartTime) +
                            "~" +
                            _vm._s(_vm.form.planEndTime)
                        ),
                      ])
                    : _vm._e(),
                  _c("v-datepicker", {
                    attrs: {
                      type: "rangedatetimer",
                      startTime: _vm.form.planStartTime,
                      endTime: _vm.form.planEndTime,
                    },
                    on: {
                      "update:startTime": function ($event) {
                        return _vm.$set(_vm.form, "planStartTime", $event)
                      },
                      "update:start-time": function ($event) {
                        return _vm.$set(_vm.form, "planStartTime", $event)
                      },
                      "update:endTime": function ($event) {
                        return _vm.$set(_vm.form, "planEndTime", $event)
                      },
                      "update:end-time": function ($event) {
                        return _vm.$set(_vm.form, "planEndTime", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              !_vm.isView
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "检查对象统一频次",
                          prop: "isPeriodAllSame",
                          rules: [
                            {
                              required: true,
                              message: "请确认检查对象是否统一频次",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-select", {
                          attrs: { options: _vm.isPeriodAllSameOps },
                          model: {
                            value: _vm.form.isPeriodAllSame,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isPeriodAllSame", $$v)
                            },
                            expression: "form.isPeriodAllSame",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.isPeriodAllSame === 1
                      ? _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "检查频次",
                                  prop: "period",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择检查周期",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: { options: _vm.periodOps },
                                  on: { change: _vm.changePeriod },
                                  model: {
                                    value: _vm.form.period,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "period", $$v)
                                    },
                                    expression: "form.period",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "20px",
                                  prop: "periodNum",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择检查次数",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("v-input-number", {
                                  staticClass: "mar-r",
                                  attrs: {
                                    min: 1,
                                    max: 10,
                                    placeholder: "请输入检查次数",
                                  },
                                  on: { change: _vm.changePeriodNum },
                                  model: {
                                    value: _vm.form.periodNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "periodNum", $$v)
                                    },
                                    expression: "form.periodNum",
                                  },
                                }),
                                _c("span", [_vm._v("次")]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              !_vm.isView
                ? _c(
                    "div",
                    { staticClass: "btn" },
                    [
                      _vm.type !== "view"
                        ? _c("v-button", {
                            staticClass: "mar-r",
                            attrs: { text: "批量新增项目" },
                            on: { click: _vm.toSelect },
                          })
                        : _vm._e(),
                      _vm.type !== "view"
                        ? _c("v-button", {
                            staticClass: "mar-r",
                            attrs: { type: "danger", text: "批量删除" },
                            on: { click: _vm.batchDelete },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("table-panel", {
                ref: "tablePanel",
                staticClass: "point-table",
                style: { padding: "0 20px", boxSizing: "border-box" },
                attrs: {
                  maxHeight: _vm.maxHeight,
                  headers: _vm.tableHeaders,
                  tableData: _vm.form.detailList,
                  isMultiSelect: !_vm.isView,
                  hasOperateColumn: false,
                },
                on: { handleSelectionChange: _vm.selectionChange },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.selectCommunityShow,
          searchUrl: _vm.getCommunityListURL,
          headers: _vm.communityTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.selectCommunityShow = $event
          },
          "update:is-show": function ($event) {
            _vm.selectCommunityShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "区域类型",
                    options: _vm.searchRegionTypeOps,
                  },
                  model: {
                    value: _vm.searchParams.searchRegionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "searchRegionType", $$v)
                    },
                    expression: "searchParams.searchRegionType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "所属区域",
                        subjoin: {
                          regionType: _vm.searchParams.searchRegionType,
                        },
                      },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "所在省" },
                  model: {
                    value: _vm.searchParams.province,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "province", $$v)
                    },
                    expression: "searchParams.province",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在城市" },
                  model: {
                    value: _vm.searchParams.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "city", $$v)
                    },
                    expression: "searchParams.city",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "所在区" },
                  model: {
                    value: _vm.searchParams.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "area", $$v)
                    },
                    expression: "searchParams.area",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "项目阶段状态",
                    options: _vm.communityStageOps,
                  },
                  model: {
                    value: _vm.searchParams.communityStage,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStage", $$v)
                    },
                    expression: "searchParams.communityStage",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "项目类型", options: _vm.communityTypeOps },
                  model: {
                    value: _vm.searchParams.communityType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityType", $$v)
                    },
                    expression: "searchParams.communityType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "项目状态", options: _vm.communityStatusOps },
                  model: {
                    value: _vm.searchParams.communityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityStatus", $$v)
                    },
                    expression: "searchParams.communityStatus",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("multi-select", {
        ref: "multiPartake",
        attrs: {
          title: "计划执行人",
          isShow: _vm.partakeShow,
          searchUrl: _vm.selectEnterpriseUserInfoListURL,
          headers: _vm.partakeTableHeader,
          searchParams: _vm.partakeSearchParams,
          extraParams: _vm.partakeExtraParams,
          responseParams: _vm.partakeResponseParams,
          responseKey: _vm.partakeResponseKey,
          backFill: _vm.form.executorIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.partakeShow = $event
          },
          "update:is-show": function ($event) {
            _vm.partakeShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "executorIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "executorIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名", placeholder: "请输入姓名" },
                  model: {
                    value: _vm.partakeSearchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.partakeSearchParams, "userName", $$v)
                    },
                    expression: "partakeSearchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号", placeholder: "请输入手机号" },
                  model: {
                    value: _vm.partakeSearchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.partakeSearchParams, "mobileNum", $$v)
                    },
                    expression: "partakeSearchParams.mobileNum",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }