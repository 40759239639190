/** 检查表模板 */
// 列表
const getQualityCheckPlanListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckPlanList`
// 列表导出
const exportUrl = `${API_CONFIG.butlerBaseURL}qualityManagement/exportQualityCheckPlanList`
// 执行/终止检查计划
const startOrStopQualityCheckPlanURL = `${API_CONFIG.butlerBaseURL}qualityManagement/startOrStopQualityCheckPlan`
// 详情
const getQualityCheckPlanInfoByIdURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckPlanInfoById`
// 新增类目
const addQualityCheckPlanURL = `${API_CONFIG.butlerBaseURL}qualityManagement/addQualityCheckPlan`
// 编辑类目
const updateQualityCheckPlanURL = `${API_CONFIG.butlerBaseURL}qualityManagement/updateQualityCheckPlan`
/* 多选框 */
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// 获取分公司列表
const getRegionsListURL = `${API_CONFIG.controlBaseURL}region/select2`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 获取组织树
const getEnterOrgTreeListURL = `${API_CONFIG.butlerBaseURL}enterpriseOrgInfo/getEnterOrgTreeList`
// 查询成员列表
const selectEnterpriseUserInfoListURL = `${API_CONFIG.butlerBaseURL}enterpriseUserInfo/selectEnterpriseUserInfoList`
// 检查表模板列表
const getQualityCheckTemplateListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTemplateList`
// 获取账号管理列表数据-项目
const getUserListURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`
// const getUserListURL = `/old/serverUserAction!viewList.action`

// 生成所有计划任务接口
const generatedQualityCheckPlanTaskURL = `${API_CONFIG.butlerBaseURL}qualityManagement/generatedQualityCheckPlanTask`

export {
  getQualityCheckPlanListURL,
  exportUrl,
  getQualityCheckPlanInfoByIdURL,
  addQualityCheckPlanURL,
  updateQualityCheckPlanURL,
  startOrStopQualityCheckPlanURL,
  getCommunityListURL,
  getProjectTypesURL,
  getRoomTypesURL,
  getRegionsListURL,
  getEnterOrgTreeListURL,
  selectEnterpriseUserInfoListURL,
  getQualityCheckTemplateListURL,
  getUserListURL,
  generatedQualityCheckPlanTaskURL,
}
