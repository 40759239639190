<!--
 * @Description: 品质管理-检查计划--表单页面
 * @Author: 小广
 * @Date: 2019-06-17 18:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityInspectionPlanForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="config"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      :form="form"
      @update="update"
    >
      <col2-detail>
        <!-- <col2-block title="类目信息"> -->
        <el-form-item
          label="计划名称"
          prop="name"
          :rules="[{required: true, message: '请输入计划名称', trigger: 'blur'}]"
        >
          <v-input v-model="form.name" :width="425" :maxlength="50" placeholder="请输入计划名称" />
        </el-form-item>
        <el-form-item
          label="计划层级"
          prop="scopeType"
          :rules="[{required: true, message: '请选择计划层级', trigger: 'change'}]"
        >
          <span v-if="isView">{{scopeTypeMap[form.scopeType]}}</span>
          <v-select v-else v-model="form.scopeType" :options="scopeTypeOps"/>
        </el-form-item>
        <el-form-item
          label="计划类型"
          prop="planType"
          :rules="[{required: true, message: '请选择计划类型', trigger: 'change'}]"
        >
          <span v-if="isView">{{planTypeMap[form.planType]}}</span>
          <v-select v-else v-model="form.planType" :options="planTypeOps"/>
        </el-form-item>
        <el-form-item
          label="计划责任部门"
          prop="responsibleDeptId"
          :rules="[{required: true, message: '请选择计划责任部门', trigger: 'change'}]"
        >
          <span v-if="isView">{{form.responsibleDeptName}}</span>
          <select-tree
            class="tree"
            v-loading="treeLoading"
            :value.sync="form.responsibleDeptId"
            placeholder="查询所属部门"
            :treeData="treeData"
            :treeProps="treeProps"
            :highlightCurrent="true"
            :filterNodeMethod="filterNodeMethod"
            :expand-on-click-node="false"
          />
        </el-form-item>
        <el-form-item
          v-if="!!form.responsibleDeptId"
          label="计划责任人"
          prop="responsibleUserId"
          :rules="[{required: true, message: '请选择计划责任人', trigger: 'change'}]"
        >
          <span v-if="isView">{{categoryLevelMap[form.categoryLevel]}}</span>
          <v-select v-else v-model="form.responsibleUserId" filterable :options="userOps"/>
        </el-form-item>
        <el-form-item
          v-if="!!form.responsibleDeptId && form.planType === 1"
          label="计划执行人"
          prop="executorIds"
          :rules="[{required: true, message: '请选择计划执行人', trigger: 'change'}]"
        >
            <chosen-list-panel textAs="name" :disabled="type === 'view'"  :list.sync="form.executorIds" @select="toSelectRoleIds"></chosen-list-panel>
        </el-form-item>
        <el-form-item
          label="检查表模板"
          prop="templateId"
          :rules="[{required: true, message: '请选择检查表模板', trigger: 'change'}]"
        >
          <span v-if="isView">{{form.templateName}}</span>
          <v-select v-else v-model="form.templateId" filterable :options="templateOps"/>
        </el-form-item>
        <el-form-item label="计划起止时间" :rules="timeRules" prop="planStartTime">
          <span v-if="isView">{{form.planStartTime}}~{{form.planEndTime}}</span>
          <v-datepicker
            type="rangedatetimer"
            :startTime.sync="form.planStartTime"
            :endTime.sync="form.planEndTime"
          />
        </el-form-item>
        <template v-if="!isView">
          <el-form-item
            label="检查对象统一频次"
            prop="isPeriodAllSame"
            :rules="[{required: true, message: '请确认检查对象是否统一频次', trigger: 'change'}]"
          >
            <v-select v-model="form.isPeriodAllSame" :options="isPeriodAllSameOps" />
          </el-form-item>
          <div style="display: flex;" v-if="form.isPeriodAllSame === 1">
            <el-form-item
              label="检查频次"
              prop="period"
              :rules="[{required: true, message: '请选择检查周期', trigger: 'change'}]"
            >
              <v-select v-model="form.period" :options="periodOps" @change="changePeriod"/>
            </el-form-item>
            <el-form-item
              label-width="20px"
              prop="periodNum"
              :rules="[{required: true, message: '请选择检查次数', trigger: 'blur'}]"
            >
              <v-input-number
                class="mar-r"
                :min="1"
                :max="10"
                placeholder="请输入检查次数"
                v-model="form.periodNum"
                @change="changePeriodNum"
              />
              <span>次</span>
            </el-form-item>
          </div>
        </template>
        <div class="btn" v-if="!isView">
          <v-button v-if="type !== 'view'" class="mar-r" text="批量新增项目" @click="toSelect"></v-button>
          <v-button v-if="type !== 'view'" type="danger" text="批量删除" class="mar-r" @click="batchDelete"></v-button>
          <!-- <div class="text" v-show="form.displayModel === 1">评分权重（%）累计占比：
            <span :class="{ error: totalWeight > 100 }">{{totalWeight}}</span>&nbsp;%
          </div> -->
        </div>
        <table-panel
          ref="tablePanel"
          :maxHeight="maxHeight"
          :style="{padding: '0 20px', boxSizing: 'border-box'}"
          :headers="tableHeaders"
          :tableData="form.detailList"
          :isMultiSelect="!isView"
          :hasOperateColumn="false"
          @handleSelectionChange="selectionChange"
          class='point-table'
        />
        <!-- </col2-block> -->
      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="selectCommunityShow"
      :searchUrl="getCommunityListURL"
      :headers="communityTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
    >
      <template #searchSlot>
        <v-input
          label="项目名称"
          v-model="searchParams.communityName"
        ></v-input>
        <v-select
          label="区域类型"
          v-model="searchParams.searchRegionType"
          :options="searchRegionTypeOps"
        ></v-select>
        <v-select2
          label="所属区域"
          v-model="searchParams.regionId"
          v-bind="regionParams"
          :subjoin="{regionType : searchParams.searchRegionType}"
        ></v-select2>
        <v-input label="所在省" v-model="searchParams.province"></v-input>
        <v-input label="所在城市" v-model="searchParams.city"></v-input>
        <v-input label="所在区" v-model="searchParams.area"></v-input>
        <v-select
          label="项目阶段状态"
          v-model="searchParams.communityStage"
          :options="communityStageOps"
        ></v-select>
        <v-select
          label="项目类型"
          v-model="searchParams.communityType"
          :options="communityTypeOps"
        ></v-select>
        <v-select
          label="项目状态"
          v-model="searchParams.communityStatus"
          :options="communityStatusOps"
        ></v-select>
      </template>
    </multi-select>
    <multi-select
      ref="multiPartake"
      title="计划执行人"
      :isShow.sync="partakeShow"
      :searchUrl="selectEnterpriseUserInfoListURL"
      :headers="partakeTableHeader"
      :searchParams="partakeSearchParams"
      :extraParams="partakeExtraParams"
      :responseParams="partakeResponseParams"
      :responseKey="partakeResponseKey"
      :backFill.sync="form.executorIds">
      <template #searchSlot>
          <v-input label="姓名" v-model="partakeSearchParams.userName" placeholder="请输入姓名" />
          <v-input label="手机号" v-model="partakeSearchParams.mobileNum" placeholder="请输入手机号" />
      </template>
    </multi-select>
  </div>
</template>

<script>
import {
  getCommunityListURL,
  getRegionsListURL,
  getProjectTypesURL,
  getRoomTypesURL,
  getQualityCheckPlanInfoByIdURL,
  addQualityCheckPlanURL,
  updateQualityCheckPlanURL,
  getEnterOrgTreeListURL,
  selectEnterpriseUserInfoListURL,
  getQualityCheckTemplateListURL,
  getUserListURL
} from './api'
import {
  statusMap,
  statusOps,
  scopeTypeMap,
  planTypeOps,
  planTypeMap,
  scopeTypeOps,
  isPeriodAllSameMap,
  isPeriodAllSameOps,
  periodMap,
  periodOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeOps
} from './map'
import { createInputVNode, createSelectVNode } from 'common/vdom'
import {
  Col2Detail,
  // Col2Block,
  MultiSelect,
  TablePanel,
  SelectTree,
  ChosenListPanel
} from 'components/bussiness'
// import { Switch } from 'element-ui'
// import { mapHelper } from 'common/utils'

export default {
  name: 'qualityInspectionPlanForm',
  components: {
    Col2Detail,
    MultiSelect,
    TablePanel,
    SelectTree,
    ChosenListPanel,
    // Col2Block,
    // 'el-switch': Switch
  },
  data () {
    const _this = this
    return {
      type: '',
      onOff: false,
      config: {
        queryUrl: getQualityCheckPlanInfoByIdURL
      },
      form: {
        name: '',
        responsibleDeptId: '',
        responsibleUserId: undefined,
        scopeType: undefined,
        planType: 1,
        templateId: undefined,
        planStartTime: '',
        planEndTime: '',
        isPeriodAllSame: undefined,
        period: undefined,
        periodNum: undefined,
        detailList: [],
          executorIds: []
      },
      statusMap,
      statusOps: statusOps(2),
      scopeTypeMap,
      isPeriodAllSameMap,
      isPeriodAllSameOps: isPeriodAllSameOps(2),
      periodMap,
      periodOps: periodOps(2),
      planTypeOps: planTypeOps(2),
      planTypeMap,
      responsibleUserOps: [],
      templateOps: [],
      // deptOps: [],
      userOps: [],
      timeRules: [
        {
          validator: (rule, value, callback) => {
            if (value) {
              let { planStartTime, planEndTime } = this.form
              if (!planStartTime) {
                callback(new Error(`请选择开始时间`))
              } else if (!planEndTime) {
                callback(new Error(`请选择结束时间`))
              } else if (planStartTime === planEndTime) {
                callback(new Error('计划起止时间不能相同'))
              } else {
                callback()
              }
            } else {
              callback(new Error(`请选择起止时间`))
            }
          },
          trigger: 'blur'
        },
        {
          required: true
        }
      ],
      /** select-tree */
      treeType: 2,
      treeLoading: false,
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        btn: 'operateArr'
      },
      /* 多选框 */
      selectCommunityShow: false,
      getCommunityListURL,
      communityTableHeader: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter (row) {
            return communityStatusMap[row.communityStatus]
          }
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'provinceName',
          label: '所在省'
        },
        {
          prop: 'cityName',
          label: '所在市'
        },
        {
          prop: 'countryName',
          label: '所在区'
        },
        {
          prop: 'communityPhone',
          label: '项目电话'
        }
      ],
      searchParams: {
        communityName: '',
        searchRegionType: undefined,
        regionId: '',
        province: '',
        city: '',
        area: '',
        communityStage: undefined,
        communityType: undefined,
        communityStatus: 0
      },
      regionParams: {
        searchUrl: getRegionsListURL,
        request: {
          text: 'regionName',
          value: 'regionId'
        }
      },
      responseParams: {
        id: 'id',
        name: 'communityName'
      },
      responseKey: {
        id: 'communityId',
        name: 'text'
      },
      communityStatusOps: communityStatusOps(1),
      searchRegionTypeOps: searchRegionTypeOps(1),
      communityStageOps: [],
      communityTypeOps: [],
      orgList: [],
      /* list */
      userOptions: { // 检查人下拉选项
      },
      selectIds: [],
      maxHeight: '520px',
      header1: [
          {
              default: '',
              prop: 'communityName',
              label: '检查对象'
            },
            {
              default: undefined,
              width: 250,
              prop: 'responsibleUserId',
              label: '执行人',
              formatter: (row, prop) => {
                if (row[prop] === '') {
                  row[prop] = undefined
                }
                _this.getResponsibleUserOps(row, prop)
                const opts = {
                  options: this.userOptions[row[this.responseKey.id]],
                  attrs: {
                    width: 200,
                    filterable: true
                  }
                }
                return createSelectVNode(this, row, prop, opts)
              }
            },
            {
              default: undefined,
              prop: 'period',
              width: 250,
              label: '检查频次类别',
              formatter: (row, prop) => {
                const opts = {
                  options: periodOps(2),
                  attrs: {
                    width: 200,
                    disabled: _this.form.isPeriodAllSame === 1
                  }
                }
                return createSelectVNode(this, row, prop, opts)
              }
            },
            {
              default: undefined,
              prop: 'periodNum',
              width: 250,
              label: '检查频次次数',
              formatter: (row, prop) => {
                const opts = {
                  attrs: {
                    disabled: _this.form.isPeriodAllSame === 1,
                    width: 120,
                    maxlength: 2
                  },
                  methods: {
                    input (newValue, el) {
                      let val = isNaN(parseInt(newValue)) ? undefined : parseInt(newValue)
                      val = val < 0 ? 0 : val
                      val = val > 10 ? 10 : val
                      _this.$set(row, prop, val)
                      el.changeModel(val)
                    }
                  }
                }
                return createInputVNode(this, row, prop, opts)
              }
            }
      ],
      header2: [
          {
              default: '',
              prop: 'communityName',
              label: '检查对象'
            },
            {
              default: undefined,
              prop: 'period',
              width: 250,
              label: '检查频次类别',
              formatter: (row, prop) => {
                const opts = {
                  options: periodOps(2),
                  attrs: {
                    width: 200,
                    disabled: _this.form.isPeriodAllSame === 1
                  }
                }
                return createSelectVNode(this, row, prop, opts)
              }
            },
            {
              default: undefined,
              prop: 'periodNum',
              width: 250,
              label: '检查频次次数',
              formatter: (row, prop) => {
                const opts = {
                  attrs: {
                    disabled: _this.form.isPeriodAllSame === 1,
                    width: 120,
                    maxlength: 2
                  },
                  methods: {
                    input (newValue, el) {
                      let val = isNaN(parseInt(newValue)) ? undefined : parseInt(newValue)
                      val = val < 0 ? 0 : val
                      val = val > 10 ? 10 : val
                      _this.$set(row, prop, val)
                      el.changeModel(val)
                    }
                  }
                }
                return createInputVNode(this, row, prop, opts)
              }
            }
      ],
      selectEnterpriseUserInfoListURL,
      partakeShow: false,
      partakeTableHeader: [
          {
              prop: 'userName',
              label: '姓名'
          },
          {
              prop: 'mobileNum',
              label: '手机号'
          },
          {
              prop: 'parentOrgName',
              label: '所属组织'
          },
          {
              prop: 'orgName',
              label: '管理范围'
          },
          {
              prop: 'inTime',
              label: '创建时间'
          },
      ],
      partakeSearchParams: {
        userName: '',
        mobileNum: '',
      },
      partakeResponseParams: {
        id: 'userId',
        name: 'userName'
      },
        partakeResponseKey: {
            id: 'id',
            name: 'name'
        }
    }
  },
  mounted () {
    const { id } = this.$route.query
    this.type = this.$route.query.type
    this.onOff = false
    if (id !== undefined) {
      this.onOff = true
      this.$refs.formPanel.getData({ id })
    }
    this.getTreeList()
    this.getProjectTypes()
    this.getRoomTypes()
    this.getQualityCheckTemplateListURL()
    this.$setBreadcrumb(`${id ? '编辑' : '新增'}`)
  },

  computed: {
    isEdit () {
      return this.$route.query.id !== undefined
    },
    isView () {
      return !!this.$route.query.isView
    },
    detailList () {
      return this.form.detailList
    },
    responsibleDeptId () {
      return this.form.responsibleDeptId
    },
    submitUrl () {
      if (this.type === 'view') {
        return
      } else {
        return this.isEdit ? updateQualityCheckPlanURL : addQualityCheckPlanURL
      }
    },
    scopeTypeOps () {
      // 1.计划类型为平台级别的时候，只有系统/集团账号可以操作，公司/项目账号不可以操作
      // 2.计划类型为公司级别的时候，只有系统/集团/公司账号可以操作，项目账号不可以操作
      // 102 公司 101 项目
      const userType = this.$store.state.userInfo.userType
      let type = scopeTypeOps(2)
      if (userType === '102') {
        type.splice(1,1)
      } else if (userType === '101') {
        type.splice(1,2)
      }
      return type
    },
    tableHeaders () {
      let map = {
        1: this.header2,
        2: this.header1,
      }
      return map[this.form.planType] || []
    },
      partakeExtraParams () {
        return {
            orgId: this.form.responsibleDeptId
        }
      }
  },
  watch: {
    orgList: {
      handler (val) {
        this.selectIds = []
        const list = this.form.detailList
        const arr = []
        // 1. 原有数据唯一标识集合
        const oldCodes = list.map(v => v[[this.responseKey.id]])
        // 2. orgList唯一标识集合
        const valIds = val.map(v => v[this.responseKey.id])
        // 3. 新增数据唯一标识集合+数据集合
        const addList = val.filter(v => !oldCodes.includes(v[this.responseKey.id]))
        // 4. 删除数据唯一标识集合
        const removeIds = oldCodes.filter(o => !valIds.includes(o))
        // 5. 删除数据后的原有数据集合
        const remainOldList = list.filter(a => !removeIds.includes(a[this.responseKey.id]))

        const defaultKeyValue = {
          // communityId: ''
        }
        this.tableHeaders.forEach(v => {
          defaultKeyValue[v.prop] = v.default
        })
        // 新增数据
        const formatterAddList = addList.map(v => {
          let info = {}
          Object.keys(defaultKeyValue).forEach(k => {
            info[k] = v.obj[k] || defaultKeyValue[k]
          })
          let defaultFix = {}
          if (this.form.isPeriodAllSame === 1) {
            defaultFix = {
              period: this.form.period,
              periodNum: this.form.periodNum
            }
          }
          return {
            ...info,
            [this.responseKey.id]: v[this.responseKey.id],
            ...defaultFix
          }
        })
        this.form.detailList = arr.concat(remainOldList, formatterAddList)
      },
      deep: true,
      immediate: true
    },
    responsibleDeptId: {
      handler () {
        this.getUserList()
        if (this.isEdit && this.onOff) {
          return
        }
        this.form.responsibleUserId = undefined
      },
      immediate: true
    }
    // detailList: {
    //   handler (list) {
    //     this.totalWeight = list.reduce((acc, cur) => (acc + Number(cur.weight || 0)), 0)
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  methods: {
    update (data) {
      Object.keys({ ...this.form, id: '' }).forEach(key => {
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      let prevList = data.detailList
      this.orgList = prevList && prevList.length > 0
        ? prevList.map(item => ({
          [this.responseKey.name]: item[this.responseParams.name],
          [this.responseKey.id]: item[this.responseKey.id],
          obj: item
        })) : []
        this.form.executorIds = [...data.executors]
      this.$nextTick(() => {
        this.onOff = false
      })
    },
    submitBefore (data) {
      let result = true
      data.executorIds = data.executorIds.map(it => it.id)
      let tip = ''
      data.detailList.forEach(v => {
        if (this.form.planType == 2 && !v.responsibleUserId) {
          tip = '列表项检查负责人存在空值！'
          result = false
        } else if (!v.period) {
          tip = '列表检查频次类别存在空值！'
          result = false
        } else if (!v.periodNum) {
          tip = '列表检查频次次数类别存在空值！'
          result = false
        }
      })
      if (tip) {
        this.$message(tip)
      }
      return result
    },
    changePeriod (newValue) {
      this.form.detailList.forEach(item => {
        this.$set(item, 'period', newValue)
      })
    },
    changePeriodNum (newValue) {
      this.form.detailList.forEach(item => {
        this.$set(item, 'periodNum', newValue)
      })
    },
    /* 多选框 */
    toSelect () {
      this.selectCommunityShow = true
    },
    getProjectTypes () {
      this.$axios.get(getProjectTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map(val => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityStageOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    getRoomTypes () {
      this.$axios.get(getRoomTypesURL).then(res => {
        if (res.status === '100') {
          let { data } = res
          let ops = data.map(val => {
            return {
              text: val.value,
              value: val.id
            }
          })
          this.communityTypeOps = [
            {
              text: '全部',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    getQualityCheckTemplateListURL () { // 获取检查表模板
      this.$axios.get(getQualityCheckTemplateListURL, {
        params: {
          status: 0,
          executeSearch: 1,
          curPage: 1,
          pageSize: 10000000,
          maxPage: 0,
          maxRow: 0,
          row: 0
        }
      }).then(res => {
        if (res.status === 100) {
          let { data } = res
          let ops = data.resultList.map(val => {
            return {
              text: val.name,
              value: val.id
            }
          })
          this.templateOps = [
            {
              text: '请选择',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    getUserList () {
      this.$axios.get(selectEnterpriseUserInfoListURL, {
        params: {
          orgId: this.form.responsibleDeptId,
          executeSearch: 1,
          curPage: 1,
          pageSize: 100000000,
          maxPage: 1,
          maxRow: 2,
          row: 20
        }
      }).then(res => {
        if (res.status === 100) {
          let { data } = res
          let ops = data.resultList.map(val => {
            return {
              text: val.userName,
              value: val.userId
            }
          })
          this.userOps = [
            {
              text: '请选择',
              value: undefined
            },
            ...ops
          ]
        }
      })
    },
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.treeType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    getResponsibleUserOps (row, prop) {
      if (this.userOptions[row[this.responseKey.id]]) {
        return
      }
      this.$set(this.userOptions, row[this.responseKey.id], [
        {
          text: '请选择',
          value: undefined
        }
      ])
      this.$axios.get(getUserListURL, {
        params: {
          curPage: 1,
          pageSize: 10000,
          row: 10000,
          roleType: 101,
          organizeId: row[this.responseKey.id]
        }
      }).then((res) => {
        if (res.status === '100') {
          this.userOptions[row[this.responseKey.id]] = []
          let { data } = res
          let ops = data.resultList.map(val => {
            return {
              text: val.userName,
              value: val.id
            }
          })
          this.userOptions[row[this.responseKey.id]] = [
            {
              text: '请选择',
              value: undefined
            },
            ...ops
          ]
        }
      }).catch(() => {
        this.userOptions[row[this.responseKey.id]] = []
      })
    },

    /** list */
    selectionChange (selection) { // table勾选
      this.selectIds = selection.map(v => v[this.responseKey.id])
    },
    async batchDelete () { // 批量删除
      const ids = this.selectIds
      if (ids.length === 0) {
        this.$message('请选择项目数据')
        return
      }
      let isOk = await this.$confirm(`确认删除所选数据共${ids.length}条？`)
      if (isOk) {
        const list = this.orgList
        this.orgList = list.filter(v => !ids.includes(v[this.responseKey.id]))
      }
    },

    // 辅助方法
    validCode (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入类目编码'))
      } else if (!/^[a-zA-Z]{1,6}$/.test(value)) {
        callback(new Error('请输入不超过6位的字母组合'))
      } else {
        callback()
      }
    },
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    },
      toSelectRoleIds () {
        this.partakeShow = true
      },
  }
}
</script>
<style lang="scss" scoped>
.mar-r {
  margin-right: 8px;
}
.qualityInspectionPlanForm-wrapper {
  ::v-deep .errorInput {
    border: 1px solid #F56C6C !important;
  }
  .marginRight {
    margin-right: 20px;
  }
  .device-panel {
    align-items: flex-start;
    display: flex;
    .device-panel__text {
      max-width: 275px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .device-panel-column {
      display: flex;
      flex-direction: column;
    }
  }
  .btn {
    display: flex;
    padding: 10px 20px;
    .text {
      flex: 1;
      display: flex;
      font-size: 14px;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .tree {
    width: 182px;
  }
}
</style>
